<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  labelKeyAdd="addStatementRB"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).px-4
  FormAdd(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'StatementRecordBook',
  components: {
    FormAdd: () => import('./FormAdd.vue')
  },
  data () {
    return {
      headers: [
        { value: 'created_at', text: this.$t('createDate') },
        { value: 'delivery', text: this.$t('delivery') },
        { value: 'is_payed', text: this.$t('payment') },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({ name: 'experience-statements-info', params: { ...this.$route.params, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.recordBookStatement,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getRecordBookStatement']),
    async getData (params) {
      this.getRecordBookStatement({ ...this.$route.params, params })
    }

  }
}
</script>
